<template>
  <v-container class="custom-container container" >
    <div class="list_form_card" >
      <h2>ประเภทวีดีโอ</h2>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="title"
              label="ชื่อประเภทวีดีโอ *"
              :rules="[v => !!v || 'กรุณาตั้งชื่อประเภทวีดีโอ']"
              required
          ></v-text-field>


          <v-text-field
              v-model="orders"
              label="ลำดับ"
          ></v-text-field>



          <div align="center" style="margin-top: 2rem">
            <buttons
                class="ml-2 mr-2"
            :title="id != 0 ? 'แก้ไข':'เพิ่มข้อมูล'"
            type="primary"
            :cb="validate"
            />
            <buttons
                class="ml-2 mr-2"
                title="ยกเลิก"
                type="danger"
                :cb="reset"
            />
            <buttons
                class="ml-2 mr-2"
                v-if=" id != 0 "
                title="ยกเลิกแก้ไข"
                type="danger"
                :cb="cancle"
            />
          </div>

        </v-form>
      </div>
      <div class="form_table" >
        <v-data-table
            :headers="headers"
            :items="resultData"
            :search="search"
            sort-by="calories"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.actions="{ item }">

            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
                small
                class="mr-2"
                @click="deleteItem(item.id)"
            >
              mdi-trash-can
            </v-icon>

          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>
      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import  {baseUrl } from "../const/api";
import $ from 'jquery'

import {DeleteMachineType, GetVideoType, UpdateVideoType} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import Buttons from "../components/Buttons/index.vue";

export default {
  name: 'VideoType',
  components: {Buttons},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ชื่อ', value: 'title',width:'500'},
      {text: 'ลำดับ', value: 'orders',width:'50'},
      {text: 'Actions', value: 'actions', sortable: false,width:'10'},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    title: "",
    id: 0,
    pin: "",
    show1: false,
    show4: false,
    image_preview: "",
    edit: "",
    nickname: "",
    first_name: "",
    last_name: "",
    tel: "",
    orders: 0,
    address: "",
    email: "",
    is_admin: 0,
    is_edit: false,
    baseUrl: baseUrl,
    password: '',
    password_confirm: '',
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
  }),

  methods:{
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      let formData = new FormData();

      formData.append("title",this.title);
      formData.append("orders",this.orders);

      if(this.id != 0){
        formData.append('is_edit',this.id)
      }

      await UpdateVideoType(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        switch (status) {
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `ไม่พบข้อมูล:${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      })



    },

    editItem(item){


      this.is_edit = true;
      console.log(item)
      this.title = item.title;
      this.orders = item.orders;
      this.id = item.id;


    },
    async GetData(){
      console.log(this)
      await GetVideoType((response)=>{
        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            this.resultData = data.data;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteMachineType(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
  },
  async mounted() {
    await this.GetData();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
</style>
