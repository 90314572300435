import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import User from '../views/User.vue'
import VideoType from '../views/VideoType.vue'
import Video from '../views/Video.vue'
import Recruiter from '../views/Recruiter.vue'
import RecruiterList from '../views/RecruiterList.vue'
import PageList from '../views/PageList.vue'
import Guest from '../views/Guest.vue'
import GameScore from '../views/GameScore.vue'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/Video',
    name: 'Video',
    component: Video
  },
  {
    path: '/videoType',
    name: 'videoType',
    component: VideoType
  },
  {
    path: '/recruiter',
    name: 'Recruiter',
    component: Recruiter
  },
  {
    path: '/guest',
    name: 'Guest',
    component: Guest
  },
  {
    path: '/GameScore',
    name: 'GameScore',
    component: GameScore
  },
  {
    path: '/recruiterlist/:code',
    name: 'RecruiterList',
    component: RecruiterList
  },
  {
    path: '/pagelist/:code',
    name: 'PageList',
    component: PageList
  },
  {
    path: '/user',
    name: 'user',
    component: User
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
