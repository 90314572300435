<template>
  <v-container class="custom-container container" >
    <div class="list_form_card" >
      <h2>ข้อมูลผู้เข้าชม</h2>

        <v-text-field
            v-model="search"
            label="ค้นหา"
            required
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="resultData"
            :search="search"
            sort-by="calories"
            class="elevation-1"

            :options.sync="options"
            sortBy="id"
            sortDesc="true"
            :server-items-length="totalItems"
            :loading="loading"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.users_code="{ item }" >
            <div align="left" >
              <font v-if="!item.user " >สมัครด้วยตัวเอง</font>
              <font v-else>{{ item.user.code }}  {{ item.user.full_name }}</font>
            </div>

          </template>
          <template v-slot:item.link="{ item }">
            <div class="list_qrcode"  align="center" >
              <img v-on:click="copylink(item)"  class="qrcode-preview" :src="baseUrlMain+'/media/qrcode/'+item.code+'.png'"><br>
            </div>

          </template>

          <template v-slot:item.approve="{ item }" >
            <div
                v-if="item.approve == 'unapprove' "
                align="center" class="checkbox-container" >
              <v-checkbox
                  v-model="ids"
                  color="success"
                  :value="item.id"
                  class="custom-checkbox"
              ></v-checkbox>
            </div>

            <div
                v-if="item.approve == 'approved' "
                align="center" class="checkbox-container" >
              <font style="filter: hue-rotate(252deg);" size="4"  >✔️</font>
            </div>


          </template>
          <template v-slot:item.fullname="{ item }" >
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{  moment(item.created_at).format("DD/MM/yyyy") }}
          </template>
          <template v-slot:item.edutext="{ item }">
            <p v-if="item.is_register == 'true' " >
              {{ renderEdu(item.about.edu) }}
            </p>
          </template>
          <template v-slot:item.address="{ item }" >
            <p class="mt-2" align="left" v-if="item.is_register == 'true' " >
              บ้านเลขที่ {{ item.about.address_number }} {{item.about.street}}<br>
              จังหวัด {{ item.about.provinces.PROVINCE_NAME }}
              อำเภอ {{ item.about.amphures.AMPHUR_NAME }}<br>
              ตำบล {{ item.about.districts.DISTRICT_NAME }}
              รหัสไปรษณีย์ {{ item.about.postcode }}
            </p>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="list_icon" >
              <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                  small
                  class="mr-2"
                  @click="deleteItem(item.id)"
              >
                mdi-trash-can
              </v-icon>

              <v-icon
                  small
                  class="mr-2"
                  @click="GotoList(item.code)"
              >
                mdi-view-list-outline
              </v-icon>

              <v-icon
                  small
                  class="mr-2"
                  @click="GotoPageList(item.code)"
              >
                mdi-clipboard-text-clock
              </v-icon>


            </div>



          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>
      </div>


  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import {baseUrl, baseUrlMain, baseUrlMainLink} from "../const/api";


import {Approve, DeleteAdmin, GetGuest, UpdateUser} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import moment from "moment";

export default {
  name: 'User',
  computed: {
    moment() {
      return moment
    }
  },
  components: {},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ชื่อ-สกุล', key:'full_name' , value:'full_name'},
      {text: 'เชิญชวนจาก',align:'center',  value:'users_code'},
      {text: 'อึเมล',align:'center', value: 'email'},
      {text: 'เบอรโทร',align:'center', value: 'tel'},
      {text: 'วันเกิด',align:'center', value: 'about.dob'},
      {text: 'วุฒิการศึกษา',align:'center', key:'edu' ,value: 'edutext'},
      {text: 'ที่อยู่',align:'center', key:'provinces', value: 'address'},
      {text: 'วันที่เพิ่มข้อมูล',align:'center', value: 'created_at'},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    user: JSON.parse(localStorage.getItem('user')),
    name: "",
    id: 0,

    pin: "",
    show1: false,
    show4: false,
    checks: [1],
    type: 'serial',
    image_preview: "",
    edit: "",
    serial: "",
    nickname: "",
    username: "",
    hospital: [],
    department: [],
    ids: [],

    first_name: "",
    hospital_id: "",
    department_id: "",
    last_name: "",
    tel: "",
    address: "",
    email: "",
    is_admin: 0,
    is_edit: false,
    baseUrl: baseUrl,
    baseUrlMain: baseUrlMain,
    password: '',
    is_register: '',
    password_confirm: '',
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],

    itemsPerPage: 5,
    search: '',
    loading: true,
    totalItems: 0,
    options: {},

  }),
  watch: {
    options: {
      handler () {
        this.GetData()
      },
      deep: true,
    },
  },
  methods:{
   renderEdu(edu){
     switch (edu){
       case "1":
         return "ต่ำกว่าปริญญาตรี";
       case "2":
         return "ปริญญาตรี";
       case "3":
         return "ปริญญาตรีขึ้นไป";
       default:
         return "-";

     }

   },
   async copylink(item){
      try {
        await navigator.clipboard.writeText( baseUrlMainLink+"/"+item.code);
        console.log('Text copied to clipboard');

        this.$swal({
          title: "สำเร็จ",
          text: "คัดลอกลิ้งตัวแทนสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง"
        }).then(async () => {

        });

      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    GotoList(code){
      this.$router.push({ path: '/recruiterlist/'+code})
    },
    GotoPageList(code){
      this.$router.push({ path: '/pagelist/'+code})
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      let formData = new FormData();

      formData.append("first_name",this.first_name);
      formData.append("last_name",this.last_name);
      formData.append("username",this.username);
      formData.append("password",this.password);
      formData.append("tel",this.tel);


      if(this.password){
        formData.append("password",this.password);
      }



      if(this.id != 0){
        formData.append('is_edit',this.id)
      }


      await UpdateUser(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        switch (status) {
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          case 410 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      })



    },

    editItem(item){


      this.is_edit = true;
      console.log(item)
      this.password = null;
      this.password_confirm = null;
      this.first_name = item.first_name;
      this.last_name = item.last_name;
      this.username = item.username;
      this.tel = item.tel;
      this.pin = item.pin;
      this.is_admin = item.is_admin == '1' ? true : false ;
      this.id = item.id;


    },
    async GetData(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }
      console.warn(params)
      this.loading = true;

      if(this.is_register){
        params.is_register = this.is_register;
      }

      console.log(this)
      await GetGuest(params,(response)=>{
        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            this.resultData = data.data;
            this.totalItems = data.total;
            this.itemsPerPage = data.to;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteAdmin(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
    async Approve(){
      let Swal = this.$swal;

      const params = {
        id:this.ids
      }

      await Approve(params,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){

          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            }).then(async () => {
              await this.GetData()
            });



            break;
          default:
            break;
        }

      });

    },
    CheckLogin(){
      console.log(this.$router.currentRoute.path)
      const user = JSON.parse(localStorage.getItem('user'));
      this.user = user;
    },
  },
  async mounted() {
    await this.GetData();
    await this.CheckLogin();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
</style>
