
<template>
  <div v-on:click="cb()" class="dashboard-box" >
    <div class="single-chart">
      <svg viewBox="0 0 36 36" class="circular-chart orange">
        <path class="circle-bg-1"
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path class="circle-bg"
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path class="circle"
              stroke-dasharray="30, 100"
              d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="20.35" class="percentage">{{ data }}</text>
      </svg>
      <div class="dashboard-des" >
        <div class="dashboard-header-text" > {{ header }} </div>
        <div class="dashboard-des-text" > {{ des }}</div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name:"DashboardBox",
  props:["header","des","data","cb"],
}
</script>


<style scoped>
.dashboard-box{
  display: flex;
  justify-content: center;
  background:#E87721 ;
  border-radius: 10px;
  cursor: pointer;
}
.flex-wrapper {
  display: flex;
  flex-flow: row nowrap;
}

.single-chart {
  width: 20rem;
  justify-content: space-around ;
  padding: 1rem;
  border-radius: 10px;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 150rem;
  background: none !important;
}
.v-application .orange{
  background: none !important;
}
.circle-bg-1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 4;
  border: 2px solid #000000;
}.circle-bg {
  fill: none;
  stroke: #F4BB90;
  stroke-width: 3;
  border: 2px solid #000000;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #E87721;
}

.circular-chart.green .circle {
  stroke: #4CC790;
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.percentage {
  fill: #ffffff;
  font-family: sans-serif;
  font-size: 0.5em;
  text-anchor: middle;
}
.dashboard-header-text{
  font-size: 1.5rem;
  color: #ffffff;
}
.dashboard-des-text{
  font-size: 1.2rem;
  color: #ffffff;
}
.dashboard-des{
  margin-top: 2rem;
  line-height: 1.8rem;
}
.btn-container{
  text-align: right;
}
.btn-container button i {
  font-size: 2.5rem;
  color: white;
}
</style>