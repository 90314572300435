<template>
  <v-container class="custom-container container container-main" >
    <div class="list_form_card" >
      <h2>ข้อมูลตัวแทน</h2>
      <div v-if="id != 0" class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >


          <v-text-field
              v-model="full_name"
              label="ชื่อ-นามสกุล"
              :rules="[v => !!v || 'กรุณากรอกชื่อจริง']"
              required
          ></v-text-field>


          <v-text-field
              v-model="code"
              label="รหัสตัวแทน"
          ></v-text-field>

          <v-text-field
              v-model="agent_license"
              label="เลขที่ใบอนุญาต"
          ></v-text-field>

          <v-text-field
              v-model="email"
              label="อีเมล"
              required
              :rules=email_rules
          ></v-text-field>

          <v-text-field
              v-model="tel"
              label="เบอร์โทร"
              required
          ></v-text-field>




          <div v-if="id != 0" align="center" style="margin-top: 2rem">
            <buttons
                class="ml-2 mr-2"
                :title="id != 0 ? 'แก้ไขข้อมูลตัวแทน':'เพิ่มข้อมูล'"
                type="primary"
                :cb="validate"
            />
            <buttons
                class="ml-2 mr-2"
                title="ยกเลิก"
                type="danger"
                :cb="reset"
            />
            <buttons
                class="ml-2 mr-2"
                v-if=" id != 0 "
                title="ยกเลิกแก้ไข"
                type="danger"
                :cb="cancle"
            />
          </div>

        </v-form>
      </div>

      <div class="form_table mt-8" >

        <v-text-field
            v-model="search"
            label="ค้นหา"
            v-on:keyup="GetData"
            required
        ></v-text-field>
        <div align="left"  class="approve-container" >
          <buttons
              type="approve"
              title="อนุมัติทั้งหมด"
          />
          <buttons
          type="approve"
          title="อนุมัติที่เลือก"
          :disabled="ids.length == 0"
          :cb="Approve"
          />
          <div class="list_icon list-icon-container" style="width: 3rem" >
            <v-icon
                small
                class="excel-icon"
                @click="exportExcel"
            >
              mdi-microsoft-excel
            </v-icon>
          </div>
        </div>

        <v-data-table
            :headers="headers"
            :items="resultData"
            :fields="fields"
            class="elevation-1"
            :search="search"
            :options.sync="options"
            sort-by="id"
            :sort-desc=true
            :server-items-length="totalItems"
            :loading="loading"
        >
          <template v-slot:top>
          </template>

          <template v-slot:header.check="{  }">
            <div>
            <div align="center" class="checkbox-container" >
              <v-checkbox
                  v-model="CheckedAll"
                  color="success"
                  class="custom-checkbox"
              ></v-checkbox>
            </div>
      </div>

          </template>

          <template v-slot:item.check="{ item }" >
            <div
                v-if="item.approve == 'unapprove' "
                align="center" class="checkbox-container" >
              <v-checkbox
                  v-model="ids"
                  color="success"
                  :value="item.id"
                  class="custom-checkbox"
              ></v-checkbox>
            </div>

          </template>

          <template v-slot:item.users_code="{ item }" >
            <font v-if="item.users_code == null " >สมัครด้วยตัวเอง</font>
            <font v-else>{{ item.users_code }}</font>
          </template>
          <template v-slot:item.link="{ item }">
            <div v-if="item.approve == 'approved' " class="list_qrcode"  align="center" >
              <img v-on:click="copylink(item)"  class="qrcode-preview" :src="baseUrlMain+'qrcode/'+item.codes+''" ><br>
            </div>

          </template>

          <template v-slot:item.cover="{ item }" >
            <img v-if="item.cover" class="img_thumb_profile" v-bind:src="baseUrlMain+'/'+JSON.parse(item.cover).path" />
            <img v-else class="img_thumb_profile" src="../assets/image/no-image.jpg"/>
          </template>

          <template v-slot:item.approve="{ item }" >

            <div
                v-if="item.approve == 'approved' "
                align="center" class="checkbox-container" >
              <font style="filter: hue-rotate(252deg);" size="4"  >✔️</font>
            </div>

            <div
                v-else
                align="center" class="checkbox-container" >
              <font style="filter: hue-rotate(359deg);" size="4"  >❌</font>
            </div>


          </template>
          <template v-slot:item.fullname="{ item }" >
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <template v-slot:item.created_at="{ item }">
            {{  moment(item.created_at).format("DD/MM/yyyy") }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="list_icon list-icon-container" >
              <v-icon
                  small
                  class="mr-2 blue-icon"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                  small
                  class="mr-2 green-icon"
                  @click="GotoList(item.codes)"
              >
                mdi-view-list-outline
              </v-icon>

              <v-icon
                  small
                  class="mr-2 orange-icon"
                  @click="GotoPageList(item.codes)"
              >
                mdi-clipboard-text-clock
              </v-icon>

              <v-icon
                  small
                  class="mr-2 red-icon"
                  @click="deleteItem(item.id)"
              >
                mdi-trash-can
              </v-icon>


            </div>



          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>

        <div align="left"  class="approve-container" >
          <buttons
              type="approve"
              title="อนุมัติทั้งหมด"
          />
          <buttons
              type="approve"
              title="อนุมัติที่เลือก"
              :disabled="ids.length == 0"
              :cb="Approve"
          />
          <div class="list_icon list-icon-container" style="width: 3rem" >
            <v-icon
                small
                class="excel-icon"
                @click="exportExcel"
            >
              mdi-microsoft-excel
            </v-icon>
          </div>
        </div>

      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import {baseUrl, baseUrlMain} from "../const/api";


import {Approve, DeleteUser, GetUser, UpdateUser} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import Buttons from "../components/Buttons/index.vue";
import moment from "moment";

export default {
  name: 'User',
  computed: {
    CheckedAll: {
      get: function () {
        console.warn("CheckedAll")
        console.warn(this.resultData.filter((res)=> res.approve == 'unapprove' ).length)
        console.warn("CheckedAll")

        return this.resultData? this.ids.length > 0 && this.ids.length == this.resultData.filter((res)=> res.approve == 'unapprove' ).length : false;
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.resultData.filter((res)=> res.approve == 'unapprove' ).forEach(function (result) {
            selected.push(result.id);
          });
        }

        this.ids = selected;
      }
    },
    moment() {
      return moment
    }
  },
  components: {Buttons},
  data: () => ({
    selectAll:[],
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'Approve',align:'center', key:'code' , value:'check', sortable: false},
      {text: 'Approve',align:'center', key:'code' , value:'approve'},
      {text: 'รหัสตัวแทน',align:'center', key:'code' , value:'code'},
      {text: 'เลขที่ใบอนุญาต',align:'center', key:'agent_license' , value:'agent_license'},
      {text: 'ชื่อ-สกุล', width:'150', key:'full_name' , value:'full_name'},
      {text: 'จำนวน guest',align:'center', key:'count' , value:'count'},
      {text: 'จำนวน guest ที่เข้าชม',align:'center', key:'count_visit' , value:'count_visit'},
      {text: 'ลิ้งค์/qrcode ตัวแทน',align:'center',  value:'link'},
      {text: 'รหัสเชิญชวน',align:'center', width:'150', value:'users_code'},
      {text: 'อึเมล',align:'center', value: 'email'},
      {text: 'เลขท้ายบัตร',align:'center', value: 'lastidcard'},
      {text: 'เบอรโทร',align:'center', value: 'tel'},
      {text: 'วันที่เพิ่มข้อมูล',align:'center', value: 'created_at'},
      {text: 'การจัดการ',align:'center',width:'50', value: 'actions', sortable: false},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    user: JSON.parse(localStorage.getItem('user')),
    name: "",
    id: 0,

    pin: "",
    show1: false,
    show4: false,
    checks: [1],
    type: 'serial',
    image_preview: "",
    edit: "",
    search: "",
    serial: "",
    nickname: "",
    username: "",
    hospital: [],
    department: [],
    ids: [],
    full_name: "",
    fields: "",
    hospital_id: "",
    department_id: "",
    last_name: "",
    code: "",
    tel: "",
    address: "",
    email: "",
    is_admin: 0,
    is_edit: false,
    baseUrl: baseUrl,
    baseUrlMain: baseUrlMain,
    password: '',
    agent_license: '',
    password_confirm: '',
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
    itemsPerPage: 5,
    loading: true,
    totalItems: 0,
    options: {},
  }),
  watch: {
    options: {
      handler () {
        this.GetData()
      },
      deep: true,
    },
  },
  methods:{
   async copylink(item){
      try {
        await navigator.clipboard.writeText( "https://fwdsucceed.com/card?code="+item.codes);
        console.log('Text copied to clipboard');

        this.$swal({
          title: "สำเร็จ",
          text: "คัดลอกลิ้งตัวแทนสำเร็จ",
          icon: "success",
          confirmButtonText: "ตกลง"
        }).then(async () => {

        });

      } catch (err) {
        console.error('Failed to copy: ', err);
      }
    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    GotoList(code){
      this.$router.push({ path: '/recruiterlist/'+code})
    },
    GotoPageList(code){
      this.$router.push({ path: '/pagelist/'+code})
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      let formData = new FormData();

      formData.append("full_name",this.full_name);
      formData.append("code",this.code);
      formData.append("email",this.email);
      formData.append("tel",this.tel);
      formData.append("agent_license",this.agent_license);


      if(this.password){
        formData.append("password",this.password);
      }



      if(this.id != 0){
        formData.append('is_edit',this.id)
      }


      await UpdateUser(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        switch (status) {
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          case 410 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      })



    },

    editItem(item){


      this.is_edit = true;
      console.log(item)
      this.password = null;
      this.password_confirm = null;
      this.full_name = item.full_name;
      this.email = item.email;
      this.code = item.code;
      this.agent_license = item.agent_license;
      this.tel = item.tel;
      this.pin = item.pin;
      this.is_admin = item.is_admin == '1' ? true : false ;
      this.id = item.id;


    },
    async GetData(){

      console.warn("SEARCH")
      console.warn(sortBy, sortDesc)
      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }

      if(this.search){
        params.keyword = this.search
      }

      this.ids = [];
      console.warn(params)
      this.loading = true;

      console.log(this)
      await GetUser(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loading = false;
        switch (status){
          case 200:
            this.resultData = data.data;
            this.totalItems = data.total;
            this.itemsPerPage = data.to;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteUser(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
    async exportExcel(){
     window.location = "https://api.fwdsucceed.com/export_recruiter";
    },
    async Approve(){

      let Swal = this.$swal;

      const params = {
        id:this.ids
      }

      await Approve(params,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){

          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            }).then(async () => {
              await this.GetData()
            });



            break;
          default:
            break;
        }

      });

    },
    CheckLogin(){
      console.log(this.$router.currentRoute.path)
      const user = JSON.parse(localStorage.getItem('user'));
      this.user = user;
    },
  },
  async mounted() {
    await this.GetData();
    await this.CheckLogin();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
</style>
