<template>
  <v-container class="custom-container container" >
    <div class="list_form_card" >
      <h2>ข้อมูลครู</h2>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="first_name"
              label="ชื่อ"
              :rules="[v => !!v || 'กรุณากรอกชื่อจริง']"
              required
          ></v-text-field>

          <v-text-field
              v-model="last_name"
              label="นามสกุล"
              :rules="[v => !!v || 'กรุณากรอกนามสกุล']"
              required
          ></v-text-field>

          <v-text-field
              v-model="username"
              :rules="email_rules"
              label="อีเมล"
              required
              :disabled="is_edit"
          ></v-text-field>

          <v-text-field
              v-model="tel"
              :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
              label="เบอร์โทร"
              required
              :disabled="is_edit"
          ></v-text-field>



          <v-text-field
              v-if="!is_edit"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[v => !!v || 'กรุณากรอกรหัสผ่าน']"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="รหัสผ่าน"
              counter
              @click:append="show1 = !show1"
          ></v-text-field>

          <v-text-field
              v-if="!is_edit"
              v-model="password_confirm"
              :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[v => v === this.password || 'รหัสผ่านไม่ตรงกัน']"
              :type="show4 ? 'text' : 'password'"
              name="input-10-2"
              label="ยืนยันรหัสผ่านใหม่"
              :error="this.password !== this.password_confirm"
              @click:append="show4 = !show4"
          ></v-text-field>


          <v-text-field
              v-if="is_edit"
              v-model="password"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show1 ? 'text' : 'password'"
              name="input-10-1"
              label="รหัสผ่าน"
              counter
              @click:append="show1 = !show1"
          ></v-text-field>

          <v-text-field
              v-if="is_edit"
              v-model="password_confirm"
              :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[v => v === this.password || 'รหัสผ่านไม่ตรงกัน']"
              :type="show4 ? 'text' : 'password'"
              name="input-10-2"
              label="ยืนยันรหัสผ่านใหม่"
              :error="this.password !== this.password_confirm"
              @click:append="show4 = !show4"
          ></v-text-field>



          <div align="center" style="margin-top: 2rem">
            <v-btn
                class="mr-4 update_btn primary"
                @click="validate"
            >
              {{ id != 0 ? 'แก้ไข':'เพิ่มข้อมูล' }}
            </v-btn>

            <v-btn
                style="color:#fff !important;"
                class="mr-4  btn_danger"
                @click="reset"
            >
              ยกเลิก
            </v-btn>

            <v-btn
                v-if=" id != 0 "
                class="mr-4 reset_btn"
                @click="cancle"
            >
              ยกเลิกแก้ไข
            </v-btn>
          </div>

        </v-form>
      </div>
      <div class="form_table" >

        <v-text-field
            v-model="search"
            label="ค้นหา"
            required
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="resultData"
            :search="search"
            sort-by="calories"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.fullname="{ item }">
            {{ item.first_name }} {{ item.last_name }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
                small
                class="mr-2"
                @click="deleteItem(item.id)"
            >
              mdi-trash-can
            </v-icon>

          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>
      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import  {baseUrl } from "../const/api";


import {DeleteAdmin, GetDepartment, GetHospital, GetUser, UpdateUser} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: 'User',
  components: {},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ชื่อ-สกุล', key:'first_name' , value:'first_name'},
      {text: 'สกุล', key:'last_name' , value:'last_name'},
      {text: 'อึเมล', value: 'username'},
      {text: 'เบอรโทร', value: 'tel'},
      {text: 'วันที่เพิ่มข้อมูล', value: 'create_at'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    user: JSON.parse(localStorage.getItem('user')),
    name: "",
    id: 0,
    pin: "",
    show1: false,
    show4: false,
    type: 'serial',
    image_preview: "",
    edit: "",
    search: "",
    serial: "",
    nickname: "",
    username: "",
    hospital: [],
    department: [],

    first_name: "",
    hospital_id: "",
    department_id: "",
    last_name: "",
    tel: "",
    address: "",
    email: "",
    is_admin: 0,
    is_edit: false,
    baseUrl: baseUrl,
    password: '',
    password_confirm: '',
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
  }),
  methods:{
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      let formData = new FormData();

      formData.append("first_name",this.first_name);
      formData.append("last_name",this.last_name);
      formData.append("username",this.username);
      formData.append("password",this.password);
      formData.append("tel",this.tel);


      if(this.password){
        formData.append("password",this.password);
      }



      if(this.id != 0){
        formData.append('is_edit',this.id)
      }


      await UpdateUser(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        switch (status) {
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          case 410 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      })



    },

    editItem(item){


      this.is_edit = true;
      console.log(item)
      this.password = null;
      this.password_confirm = null;
      this.first_name = item.first_name;
      this.last_name = item.last_name;
      this.username = item.username;
      this.tel = item.tel;
      this.pin = item.pin;
      this.is_admin = item.is_admin == '1' ? true : false ;
      this.id = item.id;


    },
    async GetHospital(){
      console.log(this)
      await GetHospital((response)=>{
        const {status, data} = ResponseData(response)
        const hospital = [];
        switch (status){
          case 200:

            data.forEach((result)=>{
              hospital.push({
                title:result.title,
                value:result.id,
              })
            })
            this.hospital = hospital;
            break;
          default:
            break;
        }

      });
    },
    async GetDepartment(){

      this.department = [];
     let check = this.hospital.find((res)=> res.title ==  this.hospital_id);

      await GetDepartment(check.value,(response)=>{
        const {status, data} = ResponseData(response)
        const department = [];
        switch (status){
          case 200:

            data.forEach((result)=>{
              department.push({
                title:result.title,
                value:result.id,
              })
            })
            this.department = department;
            break;
          default:
            break;
        }

      });
    },
    async GetData(){

      let params = {};
      if(this.user.usertype == 'staff'){
        params = {
          hospital_id:this.user.hospital_id,
          department_id:this.user.department_id,
        }
      }

      console.log(this)
      await GetUser(params,(response)=>{
        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            this.resultData = data;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteAdmin(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
    CheckLogin(){
      console.log(this.$router.currentRoute.path)
      const user = JSON.parse(localStorage.getItem('user'));
      this.user = user;
    },
  },
  async mounted() {
    await this.GetData();
    await this.GetHospital();
    await this.CheckLogin();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
</style>
