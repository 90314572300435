<template>
  <v-container class="custom-container container" >
    <div class="list_form_card" >
      <h2>วีดีโอ</h2>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-text-field
              v-model="title"
              label="ชื่อวีดีโอ *"
              :rules="[v => !!v || 'กรุณาตั้งชื่อวีดีโอ']"
              required
          ></v-text-field>

          <v-select
              class="select_custom"
              v-model="videotype"
              :items="videoType"
              :rules="[v => !!v || 'กรุณาเลือกประเภทวีดีโอ']"
              item-text="title"
              item-value="id"
              label="ประเภทวีดีโอ *"
              variant="underlined"
          ></v-select>

          <v-checkbox
              v-if="!is_edit"
              v-model="is_youtube"
              label="Youtube"
          ></v-checkbox>

          <v-textarea
              v-if="is_youtube"
              :rules="youtube_rules"
              v-model="youtube"
              v-on:keyup="CheckYoutube"
              label="ลิ้ง Youtube *"
          ></v-textarea>

          <div v-if="youtubePreview && is_youtube" class="image_preview_container" align="center">
            <iframe width="560" height="315"
                    :src="youtubePreview"
                    title="YouTube Player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen>
            </iframe>
          </div>






          <v-text-field
              v-model="orders"
              label="ลำดับ"
          ></v-text-field>

          <v-row v-if="!is_youtube" >
            <v-col>
              <v-file-input
                  @change="Preview_video"
                  :rules="[v => (is_edit || !!v) || 'กรุณาอัพโหลดรูป']"
                  accept="video/mp4"
                  placeholder="วีดีโอ"
                  prepend-icon="mdi-video-plus"
                  label="วีดีโอ *"
              ></v-file-input>
              <div class="image_preview_container" align="center">
                <video :src="videoUrl" v-if="video" muted ref="videoPreview" id="videoPreview" class="preview-video" controls >
                  Your browser does not support the video tag.
                </video>
              </div>
            </v-col>
          </v-row>


          <v-row>
            <v-col>
              <v-file-input
                  @change="Preview_image"
                  :rules="[v => (is_edit || !!v) || 'กรุณาอัพโหลดรูป']"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="เลือก รูปภาพปก"
                  v-model="cover_file"
                  prepend-icon="mdi-camera"
                  label="รูปภาพปก *"
              ></v-file-input>
              <div class="image_preview_container" align="center">
                <img class="img-preview" id="image_preview" src=""/>
              </div>
            </v-col>
          </v-row>





          <div v-if="!is_loading" align="center" style="margin-top: 2rem">
            <buttons
                class="ml-2 mr-2"
            :title="id != 0 ? 'บันทึก':'เพิ่มข้อมูล'"
            type="primary"
            :cb="validate"
            />
            <buttons
                class="ml-2 mr-2"
                title="ยกเลิก"
                type="danger"
                :cb="reset"
            />
            <buttons
                class="ml-2 mr-2"
                v-if=" id != 0 "
                title="ยกเลิกแก้ไข"
                type="danger"
                :cb="cancle"
            />
          </div>
          <div v-if="is_loading" class="progress-loading-container" >
            <div class="progress-loading" :style="{ width: progress+'%' }" id="progressLoading" >
            </div>
          </div>
        </v-form>
      </div>
      <div class="form_table" >
        <v-data-table
            :headers="headers"
            :items="resultData"

            :options.sync="options"
            sortBy="id"
            sortDesc="true"
            :server-items-length="totalItems"
            :loading="loading"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.cover="{ item }">
            <img v-if="item.cover" class="img_thumb" v-bind:src="baseUrlMain()+'/'+JSON.parse(item.cover).path" />
            <img v-else class="img_thumb" src="../assets/image/no-image.jpg"/>
          </template>
          <template v-slot:item.actions="{ item }">

            <div class="list-icon" >
              <v-icon
                  small
                  class="mr-2 icon-list green-icon"
                  @click="editItem(item)"
              >
                mdi-pencil
              </v-icon>

              <v-icon
                  small
                  class="mr-2 icon-list red-icon"
                  @click="deleteItem(item.id)"
              >
                mdi-trash-can
              </v-icon>
            </div>


          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>
      </div>

    </div>

  </v-container>
</template>
<script>
// @ is an alias to /src
//import {} from "../lib/controller";
// import {ResponseData} from "../lib/utility";
import {baseUrl, baseUrlMain} from "../const/api";
import $ from 'jquery'

import { GetVideo, GetVideoType, DeleteVideo, UpdateVideo} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import Buttons from "../components/Buttons/index.vue";

export default {
  name: 'VideoType',
  components: {Buttons},
  data: () => ({
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    headers: [
      {text: 'ภาพปก',key:'id', value: 'cover',width:'100'},
      {text: 'ชื่อ', value: 'title',width:'100'},
      {text: 'ประเภทวีดีโอ', value: 'types.title',width:'500'},
      {text: 'Actions', value: 'actions', sortable: false,width:'10'},
    ],
    resultData: [],
    is_loading:false,
    valid: true,
    title: "",
    youtubePreview:"",
    id: 0,
    pin: "",
    show1: false,
    show4: false,
    image_preview: "",
    edit: "",
    nickname: "",
    cover_file: "",
    video: "",
    videoUrl: "",
    orders: "",
    youtube_rules: [
      v => !!v || 'กรุณาเพิ่มลิ้ง Youtube',
      v => /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(v) || 'รูปแบบลิ้งไม่ถูกต้อง',
    ],
    videotype: "",
    videoType: [],
    youtube: "",
    first_name: "",
    last_name: "",
    tel: "",
    progress: 0,
    address: "",
    email: "",
    is_admin: 0,
    is_edit: false,
    is_youtube: false,
    baseUrl: baseUrl,
    password: '',
    password_confirm: '',
    itemsPerPage: 5,
    search: '',
    loading: true,
    totalItems: 0,
    options: {},
    email_rules: [
      v => !!v || 'กรุณากรอกอีเมล',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    PinRule: [
      (v) => !!v || "กรุณากรอก PIN",
      (v) =>
          (v && v.length <= 4) ||
          "PIN ต้องไม่เกิน 4 ตัว",
    ],
  }),
  watch: {
    options: {
      handler () {
        this.GetData()
      },
      deep: true,
    },
  },
  methods:{
    baseUrlMain() {
      return baseUrlMain
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    Preview_video(file) {
      if (file) {
        this.video = file;
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => { // Use arrow function to keep 'this' context
          const videoPreview = this.$refs.videoPreview;
          this.videoUrl = reader.result;
          //videoPreview.src = reader.result;
          videoPreview.load(); // Load the new video source
          videoPreview.play(); // Optional: play the video
        };
      }
    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    goBack(){
      this.$router.push({ path: '/manage/'})
    },
    CheckYoutube(){
      if(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+/.test(this.youtube)){
        this.youtubePreview = this.getYoutubePreview(this.youtube)
      }else{
        this.youtubePreview = "";
      }
    },
    getYoutubePreview(url) {
      const urlObj = new URL(url);
      this.youtubeCode = urlObj.searchParams.get('v');
      return "https://www.youtube.com/embed/"+urlObj.searchParams.get('v');
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async Update(){

      this.is_loading = true;

      let formData = new FormData();



      formData.append("title",this.title);
      formData.append("url",this.youtubePreview);
      formData.append("youtube",this.is_youtube);
      formData.append("video",this.video);
      formData.append("cover_file",this.cover_file);

      const type_video = this.videoType.find((res)=> res.title ===  this.videotype );
      formData.append("type_id",type_video.value);


      if(this.id != 0){
        formData.append('is_edit',this.id)
      }

      await UpdateVideo(formData, (response) => {
        console.warn(response);
        const {status, data} = ResponseData(response)
        this.is_loading = false;
        switch (status) {
          case 200:



            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;

          case 422 :

            for (const [key, value] of Object.entries(data)) {
              console.log(`${key}: ${value}`);

              this.$toast.open({
                message: `${value}`,
                type: "warning",
                duration: 10000,
                dismissible: true
              })

            }

            break;

          default:


            break;
        }

      }, (UploadEvent) => {
        if (UploadEvent.total > 0) { // Avoid division by zero
          this.progress = (UploadEvent.loaded / UploadEvent.total) * 100;
        }

        // Reset progress if it's complete
        if (this.progress >= 100) {
          this.progress = 0;
        }
        console.warn(this.progress);
      })



    },

    editItem(item){


      this.id = item.id;
      this.is_edit = true;
      console.log(item)
      this.title = item.title;
      this.videotype = item.types.title;
      this.is_youtube = item.youtube == 'false' ? false : true;
      this.youtube = item.url;

      $('#image_preview').attr('src',this.baseUrlMain()+'/'+JSON.parse(item.cover).path);
      if(this.is_youtube === true){
        this.youtubePreview = item.url
      }else{

        //console.warn(this.baseUrlMain()+'/'+JSON.parse(item.media.data).path);
        this.video = true;
        const videoPreview = this.$refs.videoPreview;
        this.videoUrl = this.baseUrlMain()+'/'+JSON.parse(item.media.data).path;
        videoPreview.load(); // Load the new video source
        videoPreview.play(); // Optional: play the video


      }



    },
    async GetData(){

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      const params = {
        page,itemsPerPage,sortDesc:sortDesc[0],sortBy:sortBy[0]
      }
      console.warn(params)
      this.loading = true;

      console.log(this)
      await GetVideo(params,(response)=>{
        const {status, data} = ResponseData(response)
        this.loading = false;
        switch (status){
          case 200:
            this.resultData = data.data;
            this.totalItems = data.total;
            this.itemsPerPage = data.to;
            break;
          default:
            break;
        }

      });
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){
      let Swal = this.$swal;

      await DeleteVideo(id,async  (response)=>{
        const {status, data} = ResponseData(response)
        console.log(data);
        switch (status){
          case 200:
            Swal({
              icon: 'success',
              title: 'Success',
            })

            await this.GetData()

            break;
          default:
            break;
        }

      });

    },
    async VideoType() {
      await GetVideoType((response)=>{
        const {status, data} = ResponseData(response)
        let videoType = [];
        switch (status){
          case 200:



            data.data.forEach((result) => {
              videoType.push({
                title: result.title,
                value: result.id,
              })
            })

            this.videoType = videoType;
            break;
          default:
            break;
        }

      });
    }
  },
  async mounted() {
    await this.GetData();
    await this.VideoType();
  }
}
</script>
<style>

.list_item img{
  width: 60%;
}
.list_item font{
  font-size: 1.5rem;
  color: black;
}
.container{
  height: 100%;
}
.img-logo{
  width: 30rem;
}
.v-main__wrap{
  height: 100%;
}
.v-main{
  height: 100%;
}
.ip_custom{
  opacity: 0;
}
.progress-loading-container{
  width: 100%;
  height: 2rem;
  background: #ffffff;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  overflow: hidden;
  border: 1px #c9c9c9 solid;
  position: relative;
}
.progress-val{
  color: #151515;
  z-index: 5;
  font-size: 1.2rem;
}
.progress-loading{
  width: 0%;
  height: 100%;
  background: #e87722;
}
.list-icon{
  display: flex;
}
.icon-list{
  padding-left: 0;
  padding-right: 0;
  padding: 0.2rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.2rem !important;
}

</style>
