var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"custom-container container"},[_c('div',{staticClass:"list_form_card"},[_c('h2',[_vm._v("ข้อมูลการเล่นเกม")]),_c('v-data-table',{staticClass:"elevation-1 mt-8",attrs:{"headers":_vm.headers,"items":_vm.resultData,"search":_vm.search,"sort-by":"calories","options":_vm.options,"sortBy":"id","sortDesc":"true","server-items-length":_vm.totalItems,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return undefined},proxy:true},{key:"item.users_code",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"align":"left"}},[(!item.user )?_c('font',[_vm._v("สมัครด้วยตัวเอง")]):_c('font',[_vm._v(_vm._s(item.user.code)+" "+_vm._s(item.user.full_name))])],1)]}},{key:"item.link",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"list_qrcode",attrs:{"align":"center"}},[_c('img',{staticClass:"qrcode-preview",attrs:{"src":_vm.baseUrlMain+'/media/qrcode/'+item.code+'.png'},on:{"click":function($event){return _vm.copylink(item)}}}),_c('br')])]}},{key:"item.approve",fn:function(ref){
var item = ref.item;
return [(item.approve == 'unapprove' )?_c('div',{staticClass:"checkbox-container",attrs:{"align":"center"}},[_c('v-checkbox',{staticClass:"custom-checkbox",attrs:{"color":"success","value":item.id},model:{value:(_vm.ids),callback:function ($$v) {_vm.ids=$$v},expression:"ids"}})],1):_vm._e(),(item.approve == 'approved' )?_c('div',{staticClass:"checkbox-container",attrs:{"align":"center"}},[_c('font',{staticStyle:{"filter":"hue-rotate(252deg)"},attrs:{"size":"4"}},[_vm._v("✔️")])],1):_vm._e()]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.renderCat(item.category))+" ")]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.score.toLocaleString())+" ")]}},{key:"item.score_reward",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.score_reward.toLocaleString())+" ")]}},{key:"item.rank",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.renderRank(item.rank))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateTime(item.created_at))+" ")]}},{key:"item.edutext",fn:function(ref){
var item = ref.item;
return [(item.is_register == 'true' )?_c('p',[_vm._v(" "+_vm._s(_vm.renderEdu(item.about.edu))+" ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.GetData()}}},[_vm._v(" Reset ")])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }