<template>
<button v-if="type == 'primary' " @click="cb" type="button" class="primary" :disabled="disabled" >{{title}}</button>
<button v-else-if="type == 'danger' " @click="cb" type="button" class="danger" :disabled="disabled" >{{title}}</button>
<button v-else-if="type == 'link' " @click="cb" type="button" class="links" :disabled="disabled" >{{title}}</button>
 <button v-else-if="type == 'approve' " @click="cb" type="button" class="approve" :disabled="disabled" >{{title}}</button>
<button v-else  >{{title}}  </button>
</template>

<script>


export default {
  name: "Buttons",
  props:["title","type","cb","disabled"],
  mounted() {

  }
}
</script>

<style >
button{
  padding:0.5rem;
  padding-right: 2rem;
  padding-left: 2rem;
}
.primary{
 background: var(--primary);
  color:#ffffff !important;
}
.danger{
  background: var(--red);
  color:#ffffff !important;
}
.links{
  background: #0097A9FF;
  color:#ffffff !important;
}
.links:hover{
  background: #19b3c5;
  color:#ffffff !important;
}
.approve{
  background: #03824F;
  color:#ffffff !important;
}
.approve:hover{
  background: #03824F;
  color:#ffffff !important;
}
</style>
