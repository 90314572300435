<template >
  <v-app  >
    <v-navigation-drawer v-if="auth && user" app
                         v-model="drawer"
                         :mini-variant.sync="mini"
                         permanent
    >

      <v-list dense>
        <v-list-item
            link
            to="/"
        >
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>หน้าหลัก</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


      <v-list-group
          :value="false"
          prepend-icon="mdi-video"
      >
        <template v-slot:activator>
          <v-list-item-title>วีดีโอ</v-list-item-title>
        </template>



        <v-list-item
            to="/video"
            link
        >
          <v-list-item-content>
            <v-list-item-title class="custom-list-item-title" >วีดีโอ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item
            to="/videoType"
            link
        >
          <v-list-item-content>
            <v-list-item-title class="custom-list-item-title" >ประเภทวีดีโอ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>





      </v-list-group>

      <v-list dense>
        <v-list-item
            to="/recruiter"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ข้อมูลตัวแทน</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


      <v-list dense>
        <v-list-item
            to="/guest"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ข้อมูลผู้เข้าชม</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


      <v-list dense>
        <v-list-item
            to="/GameScore"
            link
        >
          <v-list-item-icon>
            <v-icon>mdi-scoreboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ข้อมูลคะแนนเกม</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>



      <v-list dense>
        <v-list-item
            link
            v-on:click="Logout()"
        >
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>ออกจากระบบ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
    <v-app-bar v-if="auth" class="top_bar_container" app>
      <div class="list_menu" >
        <v-img class="logo_icon" src="./assets/image/logo.png" ></v-img>
        <v-btn
            class="no-padding hamburger_menu"
            icon
            @click.stop="mini = !mini"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </div>


      <div></div>
      <div></div>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
    <v-footer v-if="auth" class="footer_custom" app>
      <div align="center" >
        COPYRIGHT © 2024 FWD
      </div>
    </v-footer>
  </v-app>
</template>

<script>

import {baseUrl} from "./const/api";
import Vuetify from "vuetify";
import { refreshToken} from "./lib/controller";
import {ResponseData} from "./lib/utility";


export default {
  watch: {
    '$route'() {
      refreshToken((response)=>{
        const {status, data} = ResponseData(response)
        switch (status){
          case 200:
            console.log(data);
            break;
          case 201:
            localStorage.setItem('token',data.token)
            break;
          case 401:
            localStorage.clear();
            window.location.reload();
            break;
          default:
            break;
        }
      })
    },
  },
  name: 'App',
  vuetify: new Vuetify({
    theme: {
      dark: false,
      themes: {
        light: {
          primary: '#E87722',
          secondary: '#DBDFE1',
          success: '#03824F',
          danger: '#B30909',
          warning: '#ffc107',
          info: '#17a2b8',
          light: '#f8f9fa',
          dark: '#212529',
          gray: '#6c757d',
          red: '#841C3C',
          green: '#03824F',
          yellow: '#ffc107',
        }
      }
    }
  }),

  components: {},
  data () {
    return {
      version_system:"v 1.2.0",
      version_api:"0",
      version_service:"0",
      baseUrl:baseUrl,
      auth: localStorage.getItem('user') ? true:false,
      user: JSON.parse(localStorage.getItem('user')),
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' },
      ],
      mini: true,
    }
  },
  methods:{
    Logout(){
      localStorage.removeItem('user');
      window.location = "/login"
    },
    CheckLogin(){
      console.log(this.$router.currentRoute.path)
      const UserData = localStorage.getItem('user');
      if(this.$router.currentRoute.path === "/file_lists"){
        console.log("")
      }
      else if(!UserData && this.$router.currentRoute.path !== "/login"   ){
       window.location = "/login"
      }else{

        console.log("Nologin")
      }
    },
  },
  mounted() {
    this.CheckLogin()
    console.log(this.$route.name)
  },
  created() {
    //console.log(process.env)
  }

};
</script>
<style>
.footer_custom{
  justify-content: center;
}
.v-toolbar__content{
  display: flex;
  height: 64px;
  width: 100%;
  justify-content: space-between;
}
.version_show{
  padding: 1rem;
  font-size: 0.8rem;
  font-weight: 500;
}
.list_item_custom {
  color: #fc6f1a !important;
}
</style>
